import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

import { SEO, OrangeLayout, LinkTo } from '../components'

const Post = ({ pageContext: { slug }, data: { mdx: postNode } }) => {
  const post = postNode.frontmatter;

  let byline = <>
    {postNode.fields.date} &mdash; {postNode.timeToRead} Min Read &mdash; In{' '}
    {post.categories.map((cat, i) => (
      <React.Fragment key={cat}>
        {!!i && ', '}
        <LinkTo.Category name={cat}/>
      </React.Fragment>
    ))}</>;

  return <OrangeLayout title={post.title} byline={byline}>
    <SEO postPath={slug} postNode={postNode} article />
    <MDXRenderer>{postNode.code.body}</MDXRenderer>
  </OrangeLayout>;
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
}

Post.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export const postQuery = graphql`
  query postBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      frontmatter {
        title
        categories
      }
      fields {
        date(formatString: "DD/MM/YYYY")
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`
